import React from 'react'
import { Link } from "gatsby"
import loadable from '@loadable/component'
import pMinDelay from 'p-min-delay'
import { Menu, Transition } from '@headlessui/react'
import styled from 'styled-components';

import NewLogo from '../common/NewLogo'
import TwitterIcon from '../../assets/twitter.svg'
import LinkedinIcon from '../../assets/linkedin.svg'
import YoutubeIcon from '../../assets/youtube.svg'

const StyledDiv = styled.div`
  background: #981d22;
  width: 100%;
  height: 300px;
`;

const AddEventWidgetsNew = loadable(
  () => pMinDelay(import('./AddEventWidgetsNew'), 5000),
  {
    fallback: (
      <div className='lg:absolute lg:top-0 lg:right-0 w-11/12 lg:w-9/12 lg:max-w-screen-lg mx-auto grid grid-cols-1 md:grid-cols-2 gap-8 mt-6 lg:mt-16 mb-6'>
        <div className='w-full' aria-label='Command Access Upcoming Trainings'>
          <StyledDiv className='flex flex-col items-center justify-center'>
            <div><p className='text-white font-body text-lg normal-case italic'>Loading events...</p></div>
          </StyledDiv>
        </div>
        <div className='w-full' aria-label='Command Access Upcoming Trade Shows'>
          <StyledDiv className='flex flex-col items-center justify-center'>
            <div><p className='text-white font-body text-lg normal-case italic'>Loading events...</p></div>
          </StyledDiv>
        </div>
      </div>
    ),
  }
);

const TitleNew = 'font-body text-xl text-blue-200 font-semibold'
const MenuLinkNew = 'text-white text-sm mb-3 text-gray-100 text-center hover:underline'

const products = [
  { name: 'Command Packages', link: '/command-packages', desc: 'All-In-One Kits by Command Access' },
  { name: 'Low Energy Operator', link: '/low-energy-operator', desc: 'Low Energy Operator by Command Access' },
  { name: 'Mortise Locks', link: '/mortise-locks', desc: 'Electrified Mortise Locks' },
  { name: 'Cylindrical Locks', link: '/cylindrical-locks', desc: 'Electrified Cylindrical Locks' },
  { name: 'Exit Device Kits', link: '/exit-device-kits', desc: 'Motorized & Solenoid Latch Pullback Kits' },
  { name: 'Exit Devices', link: '/exit-devices', desc: 'Motorized & Electrified Exit Devices' },
  { name: 'Exit Trims', link: '/exit-trims', desc: 'Electrified Exit Trims' },
  { name: 'Power Supplies', link: '/power-supplies', desc: 'Power Supplies, Power Sources & Relay Boards' },
  { name: 'Power Transfers', link: '/power-transfers', desc: 'Energy Transfer Hinges & Door Loops' },
  { name: 'Accessories', link: '/accessories', desc: 'Accessories for Electrified Hardware' },
]

const resources = [
  { name: 'Forms & Documents', link: '/forms', desc: 'Check Command Access Forms & Documents that you can request and download.' },
  { name: 'Blog', link: '/blog', desc: 'Learn about our thoughts and perspectives on a range other than industry specific topics' },
  { name: 'Product Distributors', link: '/distributors', desc: 'Find Command Access distributors in or near your area.' },
  { name: 'Sales Representatives', link: '/sales-representatives', desc: 'Find Command Access sales representatives in or near your area.' },
]

const company = [
  { name: 'About Us', link: '/about', desc: 'Learn more about Command Access.' },
  { name: 'Contact', link: '/contact', desc: 'Always Here To Help' },
]

const FooterNavNewUpdateVersion = () => {
  return (
    <footer className='bg-gray-900 pt-16 pb-16'>
      <div className="w-56 mx-auto mb-12 lg:hidden xl:hidden">
        <Link to="/">
          <NewLogo />
        </Link>

        <div className='w-full mt-6'>
          <p className={MenuLinkNew}><a href='https://goo.gl/maps/Fwr5461No1HTcUkTA' target='_blank' rel='noopener noreferrer'>22901 La Palma Ave,<br/> Yorba Linda, CA 92887</a></p>
          <p className={MenuLinkNew}><a href='tel:888-622-2377'>888-622-2377</a></p>
          <p className={MenuLinkNew}><a href='mailto:orders@commandaccess.com'>orders@commandaccess.com</a></p>
        </div>
      </div>

      <div className='relative w-11/12 lg:h-96 xl:max-w-screen-xl mx-auto'>
        <div className='grid grid-cols-2 md:grid-cols-4 gap-3 mx-auto mb-4 pb-8'>
          <div className="w-56 mx-auto hidden lg:block">
            <Link to="/">
              <NewLogo />
            </Link>

            <div className='w-full mt-6'>
              <p className={MenuLinkNew}><a href='https://goo.gl/maps/Fwr5461No1HTcUkTA' target='_blank' rel='noopener noreferrer'>22901 La Palma Ave,<br/> Yorba Linda, CA 92887</a></p>
              <p className={MenuLinkNew}><a href='tel:888-622-2377'>888-622-2377</a></p>
              <p className={MenuLinkNew}><a href='mailto:orders@commandaccess.com'>orders@commandaccess.com</a></p>
            </div>
          </div>

          <div className="relative">
            <Menu>
              {({ open }) => (
                <>
                  <Menu.Button className="group inline-flex items-center space-x-1 focus:outline-none">
                    <span className={TitleNew}>Products</span>
                    <svg className="text-white fill-current h-5 w-5 group-hover:text-white group-focus:text-white transition ease-in-out duration-150" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                      <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                    </svg>
                  </Menu.Button>

                  {/* Use the Transition + open render prop argument to add transitions. */}
                  <Transition
                    show={open}
                    enter="transition ease-out duration-200"
                    enterFrom="transform opacity-0 translate-y-1"
                    enterTo="transform opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="transform opacity-100 translate-y-0"
                    leaveTo="transform opacity-0 translate-y-1"
                  >
                    <Menu.Items static className="absolute -ml-4 mt-2 transform focus:outline-none w-screen max-w-2xl z-50">
                    <div className="rounded-lg shadow-lg">
                      <div className="rounded-lg shadow-xs overflow-hidden">
                        <div className="z-20 relative grid grid-cols-2 gap-8 bg-white p-4">
                          {products.map((product, index) => {
                            return (
                              <Link
                                to={product.link}
                                key={index}
                                className="-m-3 p-3 flex items-start space-x-4 rounded-lg hover:bg-gray-200 transition ease-in-out duration-150"
                              >
                                <Menu.Item className="space-y-1">
                                  <div className='flex flex-col'>
                                    <p className="text-base leading-6 font-bold text-red-900">
                                      {product.name}
                                    </p>
                                    <p className="text-sm leading-5 text-gray-500">
                                      {product.desc}
                                    </p>
                                  </div>
                                </Menu.Item>
                              </Link>
                            )
                          })}
                        </div>
                      </div>
                    </div>
                    </Menu.Items>
                  </Transition>
                </>
              )}
            </Menu>
          </div>

          <div className="relative">
            <Menu>
              {({ open }) => (
                <>
                  <Menu.Button className="group inline-flex items-center space-x-1 focus:outline-none">
                    <span className={TitleNew}>Resources</span>
                    <svg className="text-white fill-current h-5 w-5 group-hover:text-white group-focus:text-white transition ease-in-out duration-150" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                      <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                    </svg>
                  </Menu.Button>

                  {/* Use the Transition + open render prop argument to add transitions. */}
                  <Transition
                    show={open}
                    enter="transition ease-out duration-200"
                    enterFrom="transform opacity-0 translate-y-1"
                    enterTo="transform opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="transform opacity-100 translate-y-0"
                    leaveTo="transform opacity-0 translate-y-1"
                  >
                    <Menu.Items static className="absolute -ml-4 mt-2 transform focus:outline-none w-screen max-w-xs z-50">
                    <div className="rounded-lg shadow-lg">
                      <div className="rounded-lg shadow-xs overflow-hidden">
                        <div className="z-20 relative grid gap-8 bg-white p-4">
                          {resources.map((partner, index) => {
                            return (
                              <Link
                                to={partner.link}
                                key={index}
                                className="-m-3 p-3 flex items-start space-x-4 rounded-lg hover:bg-gray-200 transition ease-in-out duration-150"
                              >
                                <Menu.Item className="space-y-1">
                                  <div className='flex flex-col'>
                                    <p className="text-base leading-6 font-bold text-red-900">
                                      {partner.name}
                                    </p>
                                    <p className="text-sm leading-5 text-gray-500">
                                      {partner.desc}
                                    </p>
                                  </div>
                                </Menu.Item>
                              </Link>
                            )
                          })}
                        </div>
                      </div>
                    </div>
                    </Menu.Items>
                  </Transition>
                </>
              )}
            </Menu>
          </div>

          <div className="relative">
            <Menu>
              {({ open }) => (
                <>
                  <Menu.Button className="group inline-flex items-center space-x-1 focus:outline-none">
                    <span className={TitleNew}>Company</span>
                    <svg className="text-white fill-current h-5 w-5 group-hover:text-white group-focus:text-white transition ease-in-out duration-150" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                      <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                    </svg>
                  </Menu.Button>

                  {/* Use the Transition + open render prop argument to add transitions. */}
                  <Transition
                    show={open}
                    enter="transition ease-out duration-200"
                    enterFrom="transform opacity-0 translate-y-1"
                    enterTo="transform opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="transform opacity-100 translate-y-0"
                    leaveTo="transform opacity-0 translate-y-1"
                  >
                    <Menu.Items static className="absolute -ml-4 mt-2 transform focus:outline-none w-screen max-w-xs z-50">
                    <div className="rounded-lg shadow-lg">
                      <div className="rounded-lg shadow-xs overflow-hidden">
                        <div className="z-20 relative grid gap-8 bg-white p-4">
                          {company.map((partner, index) => {
                            return (
                              <Link
                                to={partner.link}
                                key={index}
                                className="-m-3 p-3 flex items-start space-x-4 rounded-lg hover:bg-gray-200 transition ease-in-out duration-150"
                              >
                                <Menu.Item className="space-y-1">
                                  <div className='flex flex-col'>
                                    <p className="text-base leading-6 font-bold text-red-900">
                                      {partner.name}
                                    </p>
                                    <p className="text-sm leading-5 text-gray-500">
                                      {partner.desc}
                                    </p>
                                  </div>
                                </Menu.Item>
                              </Link>
                            )
                          })}
                        </div>
                      </div>
                    </div>
                    </Menu.Items>
                  </Transition>
                </>
              )}
            </Menu>
          </div>
        </div>

        <AddEventWidgetsNew />
      </div>

      <div className='w-11/12 xl:max-w-screen-xl flex flex-col md:flex-row md:items-start mx-auto pt-4 border-t mt-6'>
        <div className='flex items-center w-full md:w-1/3 sm:mb-6'>
          <div className='w-4 mr-8'><a href='https://twitter.com/teamCommandA' target='_blank' rel='noopener noreferrer'><TwitterIcon /><span className='hidden'>Twitter</span></a></div>

          <div className='w-4 mr-8'><a href='https://www.linkedin.com/company/command-access-technologies/' target='_blank' rel='noopener noreferrer'><LinkedinIcon /><span className='hidden'>LinkedIn</span></a></div>

          <div className='w-6'><a href='https://www.youtube.com/user/commandaccess' target='_blank' rel='noopener noreferrer'><YoutubeIcon /><span className='hidden'>Youtube</span></a></div>
        </div>

        <div className='w-full md:w-2/3 flex flex-col md:flex-row lg:items-center items-start justify-end'>
          <div className='flex my-6 md:my-0'>
            <Link to='/accessibility'>
              <p className='text-xs text-gray-100 hover:underline mr-6'>Accessibility</p>
            </Link>
          </div>

          <div className='flex mb-6 md:my-0'>
            <Link to='/privacy'>
              <p className='text-xs text-gray-100 hover:underline mr-6'>Privacy Policy</p>
            </Link>
          </div>

          <p className='font-body text-xs text-blue-200'>© {new Date().getFullYear()} Command Access Technologies.<br className='lg:hidden' /> Website by <a href='https://www.fastweb.dev' target='_blank' rel='noopener noreferrer' className='hover:underline text-white'>fastweb.dev</a></p>
        </div>
      </div>
    </footer>
  )
}

export default FooterNavNewUpdateVersion