import React, { Fragment, useState } from 'react'
import { Link } from "gatsby"
import { Dialog, Disclosure, Transition } from '@headlessui/react'
import { MenuIcon, XIcon, ChevronDownIcon } from '@heroicons/react/outline'

import UpdateLogo from '../common/UpdateLogo'
import ContactImage from '../common/ContactImage'

const products = [
  { name: 'Command Packages', link: '/command-packages', desc: 'All-In-One Kits by Command Access' },
  { name: 'Low Energy Operator', link: '/low-energy-operator', desc: 'Low Energy Operator by Command Access' },
  { name: 'Mortise Locks', link: '/mortise-locks', desc: 'Electrified Mortise Locks' },
  { name: 'Cylindrical Locks', link: '/cylindrical-locks', desc: 'Electrified Cylindrical Locks' },
  { name: 'Exit Device Kits', link: '/exit-device-kits', desc: 'Motorized & Solenoid Latch Pullback Kits' },
  { name: 'Exit Devices', link: '/exit-devices', desc: 'Motorized & Electrified Exit Devices' },
  { name: 'Exit Trims', link: '/exit-trims', desc: 'Electrified Exit Trims' },
  { name: 'Power Supplies', link: '/power-supplies', desc: 'Power Supplies, Power Sources & Relay Boards' },
  { name: 'Power Transfers', link: '/power-transfers', desc: 'Energy Transfer Hinges & Door Loops' },
  { name: 'Accessories', link: '/accessories', desc: 'Accessories for Electrified Hardware' },
]

const resources = [
  { name: 'Product Distributors', link: '/distributors', desc: 'Find Command Access distributors in or near your area.' },
  { name: 'Sales Representatives', link: '/sales-representatives', desc: 'Find Command Access sales representatives in or near your area.' },
  { name: 'Forms & Documents', link: '/forms', desc: 'Check Command Access Forms & Documents that you can request and download' },
  { name: 'Blog', link: '/blog', desc: 'Learn about our thoughts and perspectives on a range other than industry specific topics' },
]

const MobileDrawer = () => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <div className="xl:hidden flex justify-end">
        <button
          className="text-site-gray focus:outline-none focus:ring-2 focus:ring-white bg-transparent hover:bg-transparent p-0 ml-4"
          onClick={() => setOpen(true)}
        >
          <span className="sr-only">Open panel</span>
          <MenuIcon className="h-6 w-6 text-white" aria-hidden="true" />
        </button>
      </div>

      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" static className="fixed inset-0 overflow-hidden z-50" open={open} onClose={setOpen}>
          <div className="absolute inset-0 overflow-hidden">
            <Transition.Child
              as={Fragment}
              enter="ease-in-out duration-500"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-500"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="absolute inset-0 bg-red-900 bg-opacity-75 transition-opacity" />
            </Transition.Child>
            <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <div className="relative w-screen max-w-md">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 left-0 -ml-8 pt-4 pr-2 flex sm:-ml-10 sm:pr-4">
                      <button
                        className="text-white focus:outline-none focus:ring-2 focus:ring-white bg-transparent hover:bg-transparent p-0"
                        onClick={() => setOpen(false)}
                      >
                        <span className="sr-only">Close panel</span>
                        <XIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="h-full flex flex-col py-6 bg-red-900 shadow-xl overflow-y-scroll">
                    <nav className="flex flex-col mt-4 px-4">
                      <div className="w-32">
                        <UpdateLogo />
                      </div>

                      <div className="mt-4">
                        <Link to="/">
                          <button className="w-full px-4 py-2 text-base uppercase font-medium text-left text-white bg-site-red focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                            Home
                          </button>
                        </Link>
                      </div>

                      <Disclosure as="div" className="mt-4">
                        {({ open }) => (
                          <>
                            <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-base uppercase font-medium text-left text-white bg-site-black  hover:bg-black focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                              Products
                              <ChevronDownIcon
                                className={`${
                                  open ? 'transform rotate-180' : ''
                                } w-5 h-5 text-white`}
                              />
                            </Disclosure.Button>
                            <Disclosure.Panel className="flex flex-col items-start pl-8 pr-4 pt-4 pb-2 text-sm text-gray-500">
                              {products.map((product, index) => {
                                return (
                                  <Link
                                    to={product.link}
                                    key={index}
                                    className="text-base text-center font-medium text-white hover:text-gray-700 transition ease-in-out duration-150 mb-6"
                                  >
                                    {product.name}
                                  </Link>
                                )
                              })}
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>

                      <Disclosure as="div" className="mt-4">
                        {({ open }) => (
                          <>
                            <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-base uppercase font-medium text-left text-white bg-site-black  hover:bg-black focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                              Resources
                              <ChevronDownIcon
                                className={`${
                                  open ? 'transform rotate-180' : ''
                                } w-5 h-5 text-white`}
                              />
                            </Disclosure.Button>
                            <Disclosure.Panel className="flex flex-col items-start pl-8 pr-4 pt-4 pb-2 text-sm text-gray-500">
                              {resources.map((product, index) => {
                                return (
                                  <Link
                                    to={product.link}
                                    key={index}
                                    className="text-base text-center font-medium text-white hover:text-gray-700 transition ease-in-out duration-150 mb-6"
                                  >
                                    {product.name}
                                  </Link>
                                )
                              })}
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>

                      <div className="mt-4">
                        <Link to="/about">
                          <button className="w-full px-4 py-2 text-base uppercase font-medium text-left text-white bg-site-red focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                            About Us
                          </button>
                        </Link>
                      </div>                   

                      <div className="mt-4">
                        <Link to="/contact">
                          <button className="w-full px-4 py-2 text-base uppercase font-medium text-left text-white bg-site-red focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                            Contact
                          </button>
                        </Link>
                      </div>
                    </nav>

                    <div className="mt-12 ml-8">
                      <a href="tel:888-622-2377">
                        <ContactImage />
                      </a>
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  )
}

export default MobileDrawer